import React, { useState } from 'react'
import PropTypes from 'prop-types'

import './index.css'

function AlgorithmHistory({ children, point }) {
  const [isShow, setShow] = useState(false)

  const onToggle = () => {
    setShow(!isShow)
  }

  const childs = React.Children.map(children, child =>
    React.cloneElement(child, { ...child.props, point, isShow, onToggle })
  )

  return <div className="algorithm-history pb-40 pb-lg-60">{childs}</div>
}

AlgorithmHistory.defaultProps = {
  point: 5
}

AlgorithmHistory.propTypes = {
  point: PropTypes.number,
  children: PropTypes.node
}

AlgorithmHistory.Header = function AlgorithmHistoryHeader({
  date,
  children,
  confirmed,
  confirmRender
}) {
  let content = ''
  let contentClass = ''
  if (confirmed) {
    content = 'confirmed'
    contentClass = 'c-green'
  } else {
    content = 'unconfirmed'
    contentClass = 'c-red'
  }

  return (
    <header className="">
      <h6 className="c-dark-40">{date}</h6>
      <h4 className="mt-4">{children}</h4>
      {confirmRender && <p className={contentClass}>{content}</p>}
    </header>
  )
}

AlgorithmHistory.Body = function AlgorithmHistoryBody({ children }) {
  return <div className="mt-24">{children}</div>
}

export default AlgorithmHistory
