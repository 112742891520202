const get = require('lodash/get')

const FALLBACK_LOCALE = 'en'

module.exports = function getContentByLocale(
  locale,
  contents,
  localeProp = 'locale'
) {
  let content = contents.find(cnt => get(cnt, localeProp) === locale)

  if (!content) {
    content = contents.find(cnt => get(cnt, localeProp) === FALLBACK_LOCALE)
  }

  return content
}

module.exports.default = module.exports
