import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import { graphql, Link, useStaticQuery } from 'gatsby'
import PageHeader from '@c/page-header'
import AlgorithmHistory from '@c/algorithm-history'
// import ResourcesSearchBox from '@c/resources-search-box'
import useResourcesFilter from '@h/use-resources-filter'
import { extractCategories } from '@u/extract-unique-categories'
import getContentByLocale from '@u/get-content-by-locale'
import makeSlug from '@u/make-slug'

const ALL_CATEGORIES_FILTER = {
  title: null,
  id: 0
}

const FILTER_OPTS = {
  searchFields: ['title', 'slug', 'smallDescription']
}

const query = graphql`
  query {
    translations: allDatoCmsTranslation {
      nodes {
        locale
        optionAll
      }
    }
  }
`

const History = ({
  title,
  updates,
  locale,
  dictionaryPageSlug,
  displayCategories = true,
  categoryListTitle
}) => {
  const { translations } = useStaticQuery(query)
  const categories = extractCategories(updates)
  const translation = getContentByLocale(locale, translations.nodes)
  const [filter, setFilter] = useState({})
  const filtered = useResourcesFilter(updates, filter, locale, FILTER_OPTS)
  const onCategorySelect = useCallback(
    category => {
      if (filter.category && filter.category.slug === category.slug) {
        return setFilter({}) // reset filter
      }

      setFilter({ category })
    },
    [filter, setFilter]
  )

  ALL_CATEGORIES_FILTER.title = translation.optionAll

  return (
    <div className="container mt-60 mt-lg-120">
      <div className="row d-flex align-items-center justify-content-between mb-60">
        <div className="col-12 col-lg-4 mt-0 mt-lg-32">
          <PageHeader title={title} type="h3" />
        </div>
        <div className="col-12 col-lg-6">
          {/* <ResourcesSearchBox
          locale={locale}
          categories={categories}
          filter={filter}
          setFilter={setFilter}
        /> */}
        </div>
      </div>

      <div className="row">
        {/* sidebar filter */}
        <div className="col-12 col-lg-2 mt-8">
          <div className="d-none d-lg-block u-sticky scrollable-menu">
            <p className="fs-x-small mb-16 c-text">{categoryListTitle}</p>
            {displayCategories &&
              categories.map(category => {
                return (
                  <button
                    key={category.id}
                    className={cn(
                      'content-jumplink mb-8 pb-4 border-b-white ta-left d-block',
                      filter.category &&
                        filter.category.slug === category.slug &&
                        'content-jumplink-active'
                    )}
                    onClick={() => onCategorySelect(category)}
                  >
                    <p>{category.title}</p>
                  </button>
                )
              })}
          </div>
        </div>

        <div className="col-12 col-lg-10 mt-40 mt-lg-0">
          {filtered.map(update => (
            <AlgorithmHistory key={update.id}>
              <AlgorithmHistory.Header
                confirmRender={false}
                date={
                  update.category ? (
                    <Link
                      to={makeSlug.generic(
                        locale,
                        dictionaryPageSlug,
                        update.category.slug
                      )}
                    >
                      {update.category.title}
                    </Link>
                  ) : (
                    ''
                  )
                }
              >
                <Link
                  to={makeSlug.generic(
                    update.locale,
                    dictionaryPageSlug,
                    update.slug
                  )}
                >
                  {update.title}
                </Link>
              </AlgorithmHistory.Header>
              <AlgorithmHistory.Body>
                {update.smallDescription}
              </AlgorithmHistory.Body>
            </AlgorithmHistory>
          ))}
        </div>
      </div>
    </div>
  )
}

export default History
