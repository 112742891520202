import React from 'react'
import PageHeader from '@c/page-header'
import ProjectImage from '@/components/project-image'

const Hero = ({ titleSmall, titleBig, description, image }) => (
  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 col-lg-5 order-1 order-lg-0 mt-40 mt-lg-0">
        <PageHeader topTitle={titleSmall} title={titleBig} />
        <div
          className="mt-32"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
      <div className="col-12 col-lg-7 ta-center ta-lg-right">
        {image && <ProjectImage image={image} />}
      </div>
    </div>
  </div>
)

export default Hero
