import filter from 'lodash/fp/filter'
import flatten from 'lodash/fp/flatten'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import tap from 'lodash/fp/tap'
import uniqBy from 'lodash/fp/uniqBy'
import sortBy from 'lodash/fp/sortBy'

// Extract unique categories from the "category" prop
export const extractCategories = flow(
  map('category'),
  filter(cat => !!cat),
  uniqBy('slug'),
  sortBy(x => x?.orderNumber),
  tap(categories =>
    categories.forEach(category => (category.value = category.slug))
  ) // search-box needs .value property
)

// Extract unique categories from the "categories" prop
export const extractCategoriesMulti = flow(
  map('categories'),
  flatten,
  uniqBy('slug'),
  tap(categories =>
    categories.forEach(category => (category.value = category.slug))
  ) // search-box needs .value property
)
