import { useMemo } from 'react'

const DEFAULT_SEARCH_FIELDS = ['title']

const useResourcesFilter = (data, filter, locale, opts = {}) => {
  const {
    multipleCategories = false,
    searchFields = DEFAULT_SEARCH_FIELDS
  } = opts

  return useMemo(() => {
    const search = filter.search && filter.search.toLocaleLowerCase(locale)

    // apply filters
    return data
      .filter(content => {
        if (!search) {
          return true
        }

        return searchFields.some(
          field => content[field].toLocaleLowerCase(locale).indexOf(search) >= 0
        )
      })
      .filter(content => {
        if (!filter.category) {
          return true
        }

        if (multipleCategories) {
          return content.categories.some(
            category => filter.category.slug === category.slug
          )
        }

        return (
          content.category && content.category.slug === filter.category.slug
        )
      })
  }, [data, filter, locale, searchFields, multipleCategories])
}

export default useResourcesFilter
