import Article from './Article'

export default class DictionaryCategory extends Article {
  static getTranslationBases(pageSettings) {
    return pageSettings.map(slugLocale => {
      const { locale } = slugLocale
      return {
        locale,
        value: `${slugLocale.slug}`
      }
    })
  }
}
