import React from 'react'
import PropTypes from 'prop-types'

import RedLine from './red-line'

const PageHeader = ({ topTitle, title, type, hideLine }) => (
  <div className="page-header">
    {topTitle && <h5 className="mb-4">{topTitle}</h5>}
    {type === 'h1' && <h1>{title}</h1>}
    {type === 'h2' && <h2>{title}</h2>}
    {type === 'h3' && <h3>{title}</h3>}
    {!hideLine && <RedLine className="mt-24" />}
  </div>
)

PageHeader.defaultProps = {
  type: 'h1', // h1 | h2 | h3
  hideLine: false
}

PageHeader.propTypes = {
  hideLine: PropTypes.bool,
  type: PropTypes.string,
  topTitle: PropTypes.string,
  title: PropTypes.string.isRequired
}

export default PageHeader
